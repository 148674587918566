/* eslint-disable camelcase */
import axiosInstance from 'services/common/instance';

export interface SaveCheckInPayload {
  name: string;
  phone: string;
  year_of_birth: number;
  interested_products: string;
  otp: string;
}

export interface ISaveCheckIn {
  createdAt: string;
  id: number;
  name: string;
  phone: string;
  updatedAt: string;
  interestedProducts: string;
  yearOfBirth: string;
}

export interface CheckInPayload {
  pid: string;
}

export interface ICheckIn {
  pid: string;
  createdAt: string;
  updatedAt: string;
  id: number;
}

// export interface ICheckIn extends ISaveCheckIn {
//   checkinAt: string;
//   numberCheckin: number;
// }

export const apiSaveCheckIn = async (params: SaveCheckInPayload): Promise<ISaveCheckIn> => {
  const res = await axiosInstance.post('customers', params);
  return res.data.data;
};

export const apiCheckIn = async (params: CheckInPayload): Promise<ICheckIn> => {
  const res = await axiosInstance.post('checkins', params);
  return res.data.data;
};

interface SendOTPPayload {
  phone: string;
}

export const apiSendOTP = async (params: SendOTPPayload) => {
  const res = await axiosInstance.post('customers/otp/send', params);
  return res.data.data;
};
