// import dayjs from 'dayjs';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import Community from 'assets/images/community.png';
import Footer from 'assets/images/footer.png';
import Nnris from 'assets/images/nnris_white.png';
import Nutifood from 'assets/images/nutifood_white.png';
import ThanksForJoin from 'assets/images/thanksforjoining.png';
import Welcome from 'assets/images/welcome.png';
import Image from 'components/Image';
import WrapModal from 'components/Modal';
import useDidMount from 'hooks/useDidMount';
import useSearchParams from 'hooks/useSearchParams';
import useWindowDimensions from 'hooks/useWindowDemensions';
import { apiCheckIn } from 'services/request/customer';

const CheckIn: React.FC = () => {
  const history = useHistory();
  const searchParams = useSearchParams();
  const { height } = useWindowDimensions();
  const [status, setStatus] = useState<'success' | 'error' | 'else'>();
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);
  // const [date, setDate] = useState<string>();
  const getDeviceId = async () => FingerprintJS.load()
    .then((agent) => agent.get())
    .then((result) => result.visitorId);

  const handleCheckin = async () => {
    try {
      setLoading(true);
      const deviceCodeLocal = localStorage.getItem('NUTIFOOD_CHECKIN_ID');
      const deviceId = deviceCodeLocal || await getDeviceId();
      console.log({ deviceId });
      localStorage.setItem('NUTIFOOD_CHECKIN_ID', deviceId);
      searchParams.set('fprnt', deviceId);
      history.replace({ search: searchParams.toString() });
      await apiCheckIn({ pid: deviceId });
      setStatus('success');
    } catch (error) {
      if (Array.isArray(error)) {
        const err = error.find((e) => e?.code === 1001);
        if (err) {
          setStatus('error');
        }
      } else {
        setStatus('else');
      }
    } finally {
      setLoading(false);
    }
  };

  useDidMount(() => {
    handleCheckin();
  });

  return (
    <div className="p-home" style={{ height: `${height}px` }}>
      <div className="panel">
        <div className="logo">
          <div className="nutifood">
            <Image imgSrc={Nutifood} ratio="nutifood" />
          </div>
          <div className="nnris">
            <Image imgSrc={Nnris} ratio="nnris" />
          </div>
        </div>

      </div>
      {/* <div className="nnris_big">
        <Image imgSrc={NnrisBig} ratio="nnris_big" />
      </div> */}
      {/* <WrapModal isOpen={status === 'success'}> */}
      {/* <h2> */}
      {/* MÃ XÁC THỰC HỢP LỆ
          <br />
          GỬI TẶNG BẠN 1 PHẦN
          <br />
          QUÀ TỪ CHƯƠNG TRÌNH
          <br /> */}
      {/* Chào mừng quý khách đến với triển lãm
          viện nghiên cứu dinh
          dưỡng Nutifood Thụy Điển - NNRIS */}
      {/* </h2> */}
      {/* </WrapModal> */}

      {/* <WrapModal isOpen={status === 'error'}> */}
      {/* <h2> */}
      {/* BẠN ĐÃ CHECK IN &
          <br />
          NHẬN QUÀ TẶNG
          <br />
          CHƯƠNG TRÌNH
          <br />
          VÀO LÚC
          {' '}
          {date} */}
      {/* Cám ơn quý khách
          <br />
          đã hoàn thành
          <br />
          trải nghiệm công nghệ cùng NNRIS. */}
      {/* </h2> */}
      {/* </WrapModal> */}
      {status !== 'else' ? (
        <>
          <div className="p-home_mainWrapper">
            {status === 'error' && (
            <div className="p-home_thanksForText">
              <Image imgSrc={ThanksForJoin} ratio="353x84" />
            </div>

            )}
            {status === 'success' && (
              <div className="p-home_welcomeText">
                <Image imgSrc={Welcome} ratio="450x84" />
              </div>
            )}
            <div className="p-home_community">
              <Image imgSrc={Community} ratio="360x331" />
            </div>
          </div>
          <div className="p-home_footer">
            <Image imgSrc={Footer} ratio="638x257" />
          </div>
        </>
      ) : (
        <WrapModal isOpen={status === 'else'}>
          <h2>
            Lỗi hệ thống!
            <br />
            Vui lòng thử lại
          </h2>
        </WrapModal>
      )}
    </div>
  );
};

export default CheckIn;
