import React from 'react';

import CheckIn from 'pages/CheckIn';
// import Home from 'pages/Home';
// import Scan from 'pages/Scan';

type Route = {
  id: number;
  path: string;
  component: React.FC;
}

const routes: Route[] = [
  // {
  //   id: 1,
  //   path: '/',
  //   component: Home,
  // },
  {
    id: 2,
    path: '/',
    component: CheckIn,
  },
  // {
  //   id: 3,
  //   path: '/check-in',
  //   component: Scan,
  // },
];

export default routes;
